import lazySizes from 'lazysizes';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import { $$ } from '../helpers/query-selector';
import { saveData } from '../helpers/variables';

// Image lazyload
lazySizes.cfg.init = false;

function initImageLazyLoad() {
  // Load lower resolution images if saveData
  if (saveData) {
    const images = $$('.lazyload');
    for (const image of images) image.dataset.optimumx = 1;
  }

  document.addEventListener('lazyloaded', e => {
    const wrapper = e.target.closest('.c-image__wrapper, .c-iframe__wrapper');
    if (wrapper) wrapper.classList.add('c-image__wrapper--transparent');
  });

  lazySizes.init();
}

function init() {
  initImageLazyLoad();
}

export default init;
