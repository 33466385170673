/**
 * Object that is going to deal with DOM parsing/manipulation
 *
 * @namespace Barba.Pjax.Dom
 * @type {Object}
 */
var Dom = {
  /**
   * The name of the data attribute on the container
   *
   * @memberOf Barba.Pjax.Dom
   * @type {String}
   * @default
   */
  dataNamespace: 'namespace',

  /**
   * Id of the main wrapper
   *
   * @memberOf Barba.Pjax.Dom
   * @type {String}
   * @default
   */
  wrapperClass: 'barba-wrapper',

  /**
   * Class name used to identify the containers
   *
   * @memberOf Barba.Pjax.Dom
   * @type {String}
   * @default
   */
  containerClass: 'barba-container',

  /**
   * Full HTML String of the current page.
   * By default is the innerHTML of the initial loaded page.
   *
   * Each time a new page is loaded, the value is the response of the xhr call.
   *
   * @memberOf Barba.Pjax.Dom
   * @type {String}
   */
  currentHTML: document.documentElement.innerHTML,

  /**
   * Parse the responseText obtained from the xhr call
   *
   * @memberOf Barba.Pjax.Dom
   * @private
   * @param  {String} responseText
   * @return {HTMLElement}
   */
  parseResponse: function (responseText) {
    this.currentHTML = responseText;

    var wrapper = document.createElement('div');
    wrapper.innerHTML = responseText;

    var titleEl = wrapper.querySelector('title');

    if (titleEl) document.title = titleEl.textContent;

    // Extend <head> merging
    const fixedElements = {
      'link[rel="canonical"]': 'href',
      'meta[name="robots"]': 'content'
    };

    for (const [name, attr] of Object.entries(fixedElements)) {
      const existingEl = document.querySelector(name);
      const newEl = wrapper.querySelector(name);
      if (existingEl) existingEl[attr] = newEl[attr];
    }

    const variableElements = {
      'meta[name="description"]': 'content',
      'meta[property="og:url"]': 'content',
      'meta[property="og:title"]': 'content',
      'meta[property="og:description"]': 'content',
      'meta[property="og:image"]': 'content',
      'meta[property="og:image:width"]': 'content',
      'meta[property="og:image:height"]': 'content',
      'meta[property="og:image:alt"]': 'content'
    };

    for (const [name, attr] of Object.entries(variableElements)) {
      const existingEl = document.querySelector(name);
      const newEl = wrapper.querySelector(name);

      if (newEl) {
        if (existingEl) {
          existingEl[attr] = newEl[attr];
        } else {
          document.head.insertBefore(newEl, document.head.lastChild);
        }
      } else if (existingEl) {
        existingEl.parentNode.removeChild(existingEl);
      }
    }

    return this.getContainer(wrapper);
  },

  /**
   * Get the main barba wrapper by the ID `wrapperClass`
   *
   * @memberOf Barba.Pjax.Dom
   * @return {HTMLElement} element
   */
  getWrapper: function () {
    var wrapper = document.querySelector('.' + this.wrapperClass);

    if (!wrapper) throw new Error('Barba.js: wrapper not found!');

    return wrapper;
  },

  /**
   * Get the container on the current DOM,
   * or from an HTMLElement passed via argument
   *
   * @memberOf Barba.Pjax.Dom
   * @private
   * @param  {HTMLElement} element
   * @return {HTMLElement}
   */
  getContainer: function (element) {
    if (!element) element = document.body;

    if (!element) throw new Error('Barba.js: DOM not ready!');

    var container = this.parseContainer(element);

    if (container && container.jquery) container = container[0];

    if (!container) throw new Error('Barba.js: no container found');

    return container;
  },

  /**
   * Get the namespace of the container
   *
   * @memberOf Barba.Pjax.Dom
   * @private
   * @param  {HTMLElement} element
   * @return {String}
   */
  getNamespace: function (element) {
    if (element && element.dataset) {
      return element.dataset[this.dataNamespace];
    } else if (element) {
      return element.getAttribute('data-' + this.dataNamespace);
    }

    return null;
  },

  /**
   * Put the container on the page
   *
   * @memberOf Barba.Pjax.Dom
   * @private
   * @param  {HTMLElement} element
   */
  putContainer: function (element) {
    element.style.visibility = 'hidden';

    var wrapper = this.getWrapper();
    wrapper.appendChild(element);
  },

  /**
   * Get container selector
   *
   * @memberOf Barba.Pjax.Dom
   * @private
   * @param  {HTMLElement} element
   * @return {HTMLElement} element
   */
  parseContainer: function (element) {
    return element.querySelector('.' + this.containerClass);
  }
};

export default Dom;
