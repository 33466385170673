import { $$ } from '../helpers/query-selector';
import anime from 'animejs/lib/anime.es.js';
import { easings } from '../helpers/variables';

function servicesTeaser() {
  const servicesTeaser = $$('.js-services-teaser');
  if (!servicesTeaser) return;

  servicesTeaser.forEach(el => {
    const icon = el.querySelector('.js-services-icon');

    el.style.transform = 'scale(1)';

    el.addEventListener('mouseenter', _ => {
      anime.remove(icon);

      anime({
        targets: icon,
        scale: 1.075,
        duration: 1500,
        easing: easings.easeOut
      });
    });

    el.addEventListener('mouseleave', _ => {
      anime.remove(icon);

      anime({
        targets: icon,
        scale: 1,
        duration: 1500,
        easing: easings.easeOut
      });
    });
  });
}

export default servicesTeaser;
