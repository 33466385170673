import anime from 'animejs/lib/anime.es.js';
import animateScrollTo from 'animated-scroll-to';
import { easings } from '../helpers/variables';
import { $, $$ } from '../helpers/query-selector';
import Barba from '../vendors/barba';
import slideshow from './slideshow';
import servicesIcons from './services-icons';
import servicesTeaser from './services-teaser';
import projectsTeaser from './projects-teaser';
import journalTeaser from './journal-teaser';
import sectorTeaser from './sector-teaser';
import journalList from './journal-list';
import copyButton from './copy-button';
import videoLazyload from './video-lazyload';
import teamList from './team-list';

function addProgressAnim() {
  const progressBar = $('.js-progress-bar');
  let showProgress = false;

  function playAnim(value, hide = false) {
    anime.remove(progressBar);
    anime.set(progressBar, { translateY: 0 });

    const anim = anime({
      targets: progressBar,
      scaleX: value,
      duration: 700,
      easing: easings.easeOut
    });

    if (hide) {
      anim.finished.then(_ => {
        anime({
          targets: progressBar,
          translateY: [0, '-100%'],
          duration: 300,
          delay: 0,
          easing: easings.easeOut
        });
      });
    }
  }

  Barba.Dispatcher.on('linkClicked', _ => {
    showProgress = true;
    playAnim([0, 0.25]);
  });
  Barba.Dispatcher.on('initStateChange', _ => {
    if (!showProgress) return;
    playAnim([0.25, 0.5]);
  });
  Barba.Dispatcher.on('newPageReady', _ => {
    if (!showProgress) return;
    playAnim([0.5, 0.75]);
  });
  Barba.Dispatcher.on('transitionCompleted', _ => {
    if (!showProgress) return;
    playAnim([0.75, 1], true);
  });
}

function scrollTop(container) {
  const header = $('.js-header', container);
  const scrollY = window.pageYOffset;

  if (window.pageYOffset !== 0) {
    if (scrollY < header.offsetHeight) {
      animateScrollTo(0);
    } else {
      window.scroll(0, 0);
      const anim = anime({
        targets: header,
        opacity: [0, 1],
        duration: 200,
        easing: easings.easeOutColor
      });
    }
  }
}

function pageTransition() {
  Barba.Pjax.Dom.wrapperClass = 'js-page';
  Barba.Pjax.Dom.containerClass = 'js-page-wrapper';
  Barba.Pjax.Dom.dataNamespace = 'template';

  let isProjectsFilter = false;
  let isProjectsView = false;

  Barba.Dispatcher.on('linkClicked', link => {
    isProjectsFilter = link.classList.contains('js-projects-nav-filter');
    isProjectsView = link.classList.contains('js-projects-nav-view');
  });

  const PageTransition = Barba.BaseTransition.extend({
    start: function () {
      let targets = $$('.js-main, .js-footer', this.oldContainer);

      if (isProjectsFilter) {
        targets = [
          $('.js-projects-intro', this.oldContainer),
          $('.js-projects-content', this.oldContainer)
        ];
      } else if (isProjectsView) {
        targets = $('.js-projects-content', this.oldContainer);
      }

      const anim = anime({
        targets: targets,
        opacity: [1, 0],
        duration: 200,
        easing: easings.easeOutColor
      });

      Promise.all([this.newContainerLoading, anim.finished]).then(_ => {
        let targets = $$('.js-main, .js-footer', this.newContainer);

        if (isProjectsFilter) {
          targets = [
            $('.js-projects-intro', this.newContainer),
            $('.js-projects-content', this.newContainer)
          ];
        } else if (isProjectsView) {
          targets = $('.js-projects-content', this.newContainer);
        } else {
          scrollTop(this.newContainer);
        }

        isProjectsFilter = false;
        isProjectsView = false;
        this.done();

        anime({
          targets: targets,
          opacity: [0, 1],
          duration: 200,
          easing: easings.easeOutColor
        });
      });
    }
  });

  Barba.Pjax.getTransition = function () {
    return PageTransition;
  };

  Barba.Dispatcher.on('transitionCompleted', _ => {
    slideshow();
    servicesIcons();
    servicesTeaser();
    projectsTeaser();
    journalTeaser();
    sectorTeaser();
    journalList();
    copyButton();
    videoLazyload();
    teamList();

    if ($('.js-iframe')) import('./iframe').then(m => m.default());
    if ($('.js-map')) import('./map').then(m => m.default());

    if ($('.js-video-controls'))
      import('./video-controls').then(m => m.default());
    if ($('.js-jobs-list')) import('./jobs-list').then(m => m.default());
  });

  addProgressAnim();

  Barba.Pjax.start();
}

export default pageTransition;
