import { $$ } from '../helpers/query-selector';
import { saveData, viewport } from '../helpers/variables';

// Video lazyload
function initVideoLazyLoad() {
  const videos = $$('.js-video video');

  if (!videos) return;

  function fadeInVideo(video) {
    video.classList.remove('video-lazyload');
    video.classList.add('video-lazyloaded');
    video.parentNode.classList.add('c-video__wrapper--transparent');
  }

  const playObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        if (entry.isIntersecting) {
          playObserver.unobserve(video);

          if (video.paused && !video.hasAttribute('controls') && !saveData) {
            if (video.readyState === 0 || video.readyState === 1) {
              video.addEventListener('loadeddata', () => {
                if (video.hasAttribute('muted')) {
                  video.play();
                }
              });
            } else {
              if (video.hasAttribute('muted')) {
                video.play();
              }
            }
          }
        }
      }
    },
    { rootMargin: '-30% 0%' }
  );

  const loadObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        // Load default or responsive src
        if (entry.isIntersecting) {
          const sources = $$('source', video);

          for (const source of sources) {
            if (source.dataset.src) {
              source.setAttribute('src', source.dataset.src);
            } else {
              if (viewport.m.matches) {
                source.setAttribute('src', source.dataset.srcDesktop);
              } else {
                source.setAttribute('src', source.dataset.srcMobile);
              }
            }
          }

          video.load();

          video.addEventListener('loadeddata', () => {
            fadeInVideo(video);
          });

          // Fallback
          setTimeout(() => {
            fadeInVideo(video);
          }, 3000);

          loadObserver.unobserve(video);
        }
      }
    },
    { rootMargin: '0% 0% 100% 0%' }
  );

  for (const video of videos) {
    // Load initial video
    loadObserver.observe(video);
    playObserver.observe(video);

    // Update responsive video
    viewport.m.addEventListener('change', e => {
      loadObserver.observe(video);
    });
  }
}

function init() {
  initVideoLazyLoad();
}

export default init;
