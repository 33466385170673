import { $, $$ } from '../helpers/query-selector';

function init() {
  const teaser = $('.js-sector-teaser');

  if (!teaser) return;
  const items = $$('.js-sector-teaser-item');
  const itemAnimateClass = 'l-sector-teaser__item--animate';

  items.forEach((item, i) => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add(itemAnimateClass);
          } else if (entry.target.classList.contains(itemAnimateClass)) {
            observer.unobserve(item);
          }
        }
      },
      { rootMargin: `0% 0% -60px 0%` }
    );
    observer.observe(item);
  });
}

export default init;
