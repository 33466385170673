import { $, $$ } from '../helpers/query-selector';
import { viewport } from '../helpers/variables';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

function initSlideshow(slideshow) {
  const pagination = $('.js-slideshow-pagination', slideshow);

  function createSwiper() {
    return new Swiper(slideshow, {
      modules: [Pagination, Autoplay, EffectFade],
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 500,
      autoplay: {
        delay: 5000
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: pagination,
        clickable: false
      },
      on: {
        init() {
          const images = $$('.lazyloading', slideshow);

          for (const image of images) {
            image.classList.add('lazyload');
          }
        }
      }
    });
  }

  const swiper = createSwiper();

  slideshow.swiperInstance = swiper;
}

function init() {
  for (const slideshow of $$('.js-slideshow')) {
    initSlideshow(slideshow);
  }
}

export default init;
