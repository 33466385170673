import { $$ } from '../helpers/query-selector';
import setAlert from './alert';

function init() {
  const buttons = $$('.js-copy-button');

  for (const button of buttons) {
    const copyData = button.dataset.url;
    let alertText = button.dataset.alert;

    if (!alertText) alertText = 'Copied!';

    button.addEventListener('click', e => {
      navigator.clipboard.writeText(copyData).then(setAlert(alertText));
    });
  }
}

export default init;
