import anime from 'animejs/lib/anime.es.js';
import { easings } from '../helpers/variables';
import onScroll from '../helpers/on-scroll';
import inView from '../helpers/in-view';

function getLengths(els) {
  return els.map(el => el.getTotalLength());
}

function setDashArray(els, lengths) {
  els.forEach((el, i) => {
    el.style.strokeDasharray = lengths[i];
  });
}

function animateIcon(icon, animIndex) {
  const id = icon.dataset.id;
  const globalDelay = animIndex * 200;

  if (id === 'services') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: (el, i) => {
        return i === 3 || i === 7 ? 600 : 200;
      },
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = i * 50;
        if (i === 3) delay = 350;
        else if (i > 3 && i < 7) delay += 150;
        else if (i === 7) delay = 700;
        else if (i > 7) delay += 300;
        return delay;
      }
    });
  } else if (id === 'gesundheit') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);

    lines.forEach((el, i) => {
      el.style.strokeDasharray = `0 ${lengths[i]}`;
      el.style.strokeDashoffset = lengths[i] / 2;
    });

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i] / 2, lengths[i]];
      },
      strokeDasharray: (el, i) => {
        return [`0 ${lengths[i]}`, `${lengths[i]} 0`];
      },
      duration: 600,
      easing: easings.easeOut,
      delay: (el, i) => i * 250 + globalDelay
    });
  } else if (id === 'wohnen') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);

    lines.forEach((el, i) => {
      el.style.strokeDasharray = `0 ${lengths[i]}`;
      el.style.strokeDashoffset = lengths[i] / 2;
    });

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i] / 2, lengths[i]];
      },
      strokeDasharray: (el, i) => {
        return [`0 ${lengths[i]}`, `${lengths[i]} 0`];
      },
      duration: 600,
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = 250;
        if (i === 0) delay = 0;
        return delay + globalDelay;
      }
    });
  } else if (id === 'cost') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: (el, i) => {
        return i === 4 ? 600 : 200;
      },
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = i * 50;
        if (i >= 4) delay = 400;
        return delay + globalDelay;
      }
    });
  } else if (id === 'risk') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: 200,
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = 0;
        if (i === 2 || i === 3) delay = 300;
        if (i === 4 || i === 5) delay = 500;
        //if (i === 3 || i === 5) delay += 50;
        return delay + globalDelay;
      }
    });
  } else if (id === 'deadline') {
    const els = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lines = els.splice(0, 8);
    const rects = els.splice(-3);
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: 200,
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = i * 50;
        if (i > 3) delay = 200;
        return delay + globalDelay;
      }
    });

    anime({
      targets: rects,
      opacity: [0, 1],
      duration: 200,
      opacity: [0, 1],
      easing: easings.easeOut,
      delay: (el, i) => {
        return i * 25 + 350 + globalDelay;
      }
    });
  } else if (id === 'sustainability') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: (el, i) => {
        return i === 0 ? 600 : 200;
      },
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = 0;
        if ((i == 0) | (i > 2)) delay = i * 25 + 200;
        return delay + globalDelay;
      }
    });
  } else if (id === 'quality') {
    const lines = Array.prototype.slice.call(icon.querySelectorAll('svg > *'));
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: (el, i) => {
        return i === 6 ? 600 : 200;
      },
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = i * 25;
        if (i === 6) delay = 200;
        if (i === 7) delay = 500;
        if (i === 8) delay = 600;
        return delay + globalDelay;
      }
    });
  } else if (id === 'planning') {
    const lines = Array.prototype.slice.call(
      icon.querySelectorAll('svg > *:not(:nth-child(7))')
    );
    const lengths = getLengths(lines);
    setDashArray(lines, lengths);

    anime({
      targets: lines,
      strokeDashoffset: (el, i) => {
        return [lengths[i], 0];
      },
      duration: 200,
      easing: easings.easeOut,
      delay: (el, i) => {
        let delay = i * 10;
        if (i === 5) delay = 50;
        if (i > 5) delay = i * 25 + 200;
        return delay + globalDelay;
      }
    });

    const arrow = icon.querySelector('svg > *:nth-child(7)');
    const arrowLength = getLengths([arrow])[0];
    arrow.style.strokeDasharray = `0 ${arrowLength}`;
    arrow.style.strokeDashoffset = arrowLength / 2;

    anime({
      targets: arrow,
      strokeDashoffset: [arrowLength / 2, arrowLength],
      strokeDasharray: [`0 ${arrowLength}`, `${arrowLength} 0`],
      duration: 200,
      easing: easings.easeOut,
      delay: 150 + globalDelay
    });
  }
}

function icons() {
  const servicesIcons = Array.prototype.slice.call(
    document.querySelectorAll('.js-services-icon')
  );
  if (!servicesIcons) return;
  let animIndex = 0;

  if (window.document.documentMode) {
    // … or if IE
    servicesIcons.forEach(el => el.classList.remove('anim-hidden'));
  } else {
    onScroll(_ => {
      // Used as a delay value for staggered anims
      animIndex = 0;
      servicesIcons.forEach(icon => {
        if (
          icon.classList.contains('anim-hidden') &&
          inView(icon, 0.85).visible
        ) {
          icon.classList.remove('anim-hidden');
          animateIcon(icon, animIndex);
          animIndex++;
        }
      });
    });
  }
}

export default icons;
