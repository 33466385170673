import { $ } from '../helpers/query-selector';

function setAlert(text) {
  const alert = $('.js-alert');
  const alertText = $('.js-alert-text');

  alertText.innerHTML = text;
  alert.classList.add('c-alert--visible');

  setTimeout(() => {
    alertText.innerHTML = '';
    alert.classList.remove('c-alert--visible');
  }, 2000);
}

export default setAlert;
