import BaseTransition from './Transition/BaseTransition';
import BaseView from './View/BaseView';
import BaseCache from './Cache/BaseCache';
import Dispatcher from './Dispatcher/Dispatcher';
import HistoryManager from './Pjax/HistoryManager';
import Pjax from './Pjax/Pjax';
import Prefetch from './Pjax/Prefetch';
import Utils from './Utils/Utils';

var Barba = {
  version: '1.0.0',
  BaseTransition: BaseTransition,
  BaseView: BaseView,
  BaseCache: BaseCache,
  Dispatcher: Dispatcher,
  HistoryManager: HistoryManager,
  Pjax: Pjax,
  Prefetch: Prefetch,
  Utils: Utils
};

export default Barba;
