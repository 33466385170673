import anime from 'animejs/lib/anime.es.js';
import { viewport, colors, easings } from '../helpers/variables';
import onScroll from '../helpers/on-scroll';
import inView from '../helpers/in-view';

function prepareTeaser(teaser) {
  teaser.forEach(el => {
    const bg = el.querySelector('.js-projects-teaser-bg');
    const image = el.querySelector('.js-projects-teaser-image');
    const hl = el.querySelector('.js-projects-teaser-hl');

    image.style.transform = 'scale(1.025)';

    // Only show blue if el not in view
    if (!inView(el, 0.9).visible) {
      bg.style.backgroundColor = colors.blue;
      hl.style.opacity = 0;
      el.style.opacity = 1;
      el.style.transform = `translateY(${viewport.l.matches ? 4 : 3}rem)`;
    }
  });
}

function addHover(el) {
  const image = el.querySelector('.js-projects-teaser-image');

  el.addEventListener('mouseenter', _ => {
    anime.remove(image);

    anime({
      targets: image,
      scale: 1,
      duration: 1500,
      easing: easings.easeOut
    });
  });

  el.addEventListener('mouseleave', _ => {
    anime.remove(image);

    anime({
      targets: image,
      scale: 1.025,
      duration: 1500,
      easing: easings.easeOut
    });
  });
}

function animateTeaser(el, animIndex) {
  const globalDelay = animIndex * 100;
  const bg = el.querySelector('.js-projects-teaser-bg');
  const hl = el.querySelector('.js-projects-teaser-hl');

  anime({
    targets: hl,
    translateY: [`${viewport.l.matches ? 2 : 1.5}rem`, 0],
    duration: 1500,
    easing: easings.easeOut,
    delay: globalDelay
  });

  anime({
    targets: hl,
    opacity: [0, 1],
    duration: 500,
    easing: easings.easeOutColor,
    delay: globalDelay
  });

  anime({
    targets: bg,
    opacity: [1, 0],
    duration: 300,
    easing: easings.easeOutColor,
    delay: globalDelay
  });

  // anime({
  //   targets: el,
  //   opacity: [0, 1],
  //   duration: 100,
  //   easing: easings.easeOutColor,
  //   delay: globalDelay
  // });

  anime({
    targets: el,
    translateY: [`${viewport.l.matches ? 4 : 3}rem`, '0rem'],
    duration: 1500,
    easing: easings.easeOut,
    delay: globalDelay
  });

  addHover(el);
}

function projectsTeaser() {
  const teaser = Array.prototype.slice.call(
    document.querySelectorAll('.js-projects-teaser')
  );
  if (!teaser) return;
  let animIndex = 0;

  prepareTeaser(teaser);

  onScroll(_ => {
    // Used as a delay value for staggered anims
    animIndex = 0;
    teaser.forEach(el => {
      if (el.classList.contains('anim-hidden') && inView(el, 0.9).visible) {
        el.classList.remove('anim-hidden');
        animateTeaser(el, animIndex);
        animIndex++;
      }
    });
  });
}

export default projectsTeaser;
