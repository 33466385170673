import throttle from './throttle';

function onScroll(fn) {
  function scroll() {
    fn();
  }

  scroll();

  let ticking = false;

  window.addEventListener('scroll', e => {
    if (!ticking) {
      window.requestAnimationFrame(_ => {
        scroll();
        ticking = false;
      });
    }
    ticking = true;
  });

  window.addEventListener(
    'resize',
    throttle(_ => {
      scroll();
    }, 100)
  );
}

export default onScroll;
