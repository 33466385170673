import { $, $$ } from '../helpers/query-selector.js';
import { viewport } from '../helpers/variables';
import { gsap, Draggable, InertiaPlugin } from 'gsap/all';

function init() {
  gsap.registerPlugin(Draggable, InertiaPlugin);

  const teamLists = $$('.js-team-list');

  for (const teamList of teamLists) {
    const wrapper = $('.js-team-list-wrapper', teamList);
    const row2 = $('.js-team-list-row-2', teamList);

    function updateRow2() {
      const overallWidth = wrapper.scrollWidth;
      let row2Width = row2.scrollWidth;
      row2Width += viewport.m.matches ? 140 : 80;

      const deltaWidth = overallWidth - row2Width;
      const scrollPercent =
        wrapper.scrollLeft / (overallWidth - teamList.offsetWidth);

      const offset = deltaWidth * scrollPercent;
      const indicatorPercentage = scrollPercent * 100;
      const indicatorWidth = (deltaWidth / overallWidth) * 100;
      const relativeIndicatorPercentage =
        indicatorPercentage - scrollPercent * indicatorWidth;

      teamList.style.setProperty('--offset', `${offset}px`);
      teamList.style.setProperty(
        '--indicator-percentage',
        `${relativeIndicatorPercentage}%`
      );
      teamList.style.setProperty('--indicator-width', `${indicatorWidth}%`);
    }

    Draggable.create(wrapper, {
      type: 'scrollLeft',
      bounds: teamList,
      edgeResistance: 1,
      inertia: true,
      zIndexBoost: false
      // onDrag: updateRow2,
      // onThrowUpdate: updateRow2
    });

    let ticking = false;

    wrapper.addEventListener('scroll', () => {
      if (ticking) return;

      window.requestAnimationFrame(() => {
        updateRow2();
        ticking = false;
      });

      ticking = true;
    });

    updateRow2();
  }
}

export default init;
