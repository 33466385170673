import { $, $$ } from '../helpers/query-selector';
import anime from 'animejs/lib/anime.es.js';
import throttle from '../helpers/throttle';
import { viewport, easings, colors } from '../helpers/variables';

const page = $('.js-page');
let isAnimating = false;
let isOpen = false;

function animateNav() {
  const navToggle = $('.js-nav-toggle');
  const navList = $('.js-nav-list');
  const navListItems = $$('.js-nav-list > *');
  const navIconLineEls = $$('.js-nav-icon > *:nth-child(-n + 3)');
  const navIconArrowEls = $$('.js-nav-icon > *:nth-last-child(-n + 3)');
  if (isAnimating) return;

  isAnimating = true;
  isOpen = !isOpen;

  navToggle.setAttribute('aria-expanded', isOpen.toString());
  if (isOpen) navList.style.visibility = 'visible';

  const iconLineAnim = anime({
    targets: navIconLineEls,
    duration: 500,
    strokeDashoffset: isOpen ? [0, 30] : [30, 0],
    easing: easings.easeOut,
    delay: (el, i) => {
      let delay = 0;
      if (i !== 1 && isOpen) delay = 75;
      if (i === 1 && !isOpen) delay = 75;
      return delay + (isOpen ? 0 : 200);
    }
  });

  const iconArrowAnim = anime({
    targets: navIconArrowEls,
    duration: 500,
    strokeDashoffset: (el, i) => {
      const offsets = [
        27.100000381469727, 17.697240829467773, 17.697242736816406
      ];
      return isOpen ? [offsets[i], 0] : [0, offsets[i]];
    },
    easing: easings.easeOut,
    delay: (el, i) => {
      let delay = 0;
      if (i === 0 && isOpen) delay = 100;
      if (i !== 0 && !isOpen) delay = 100;
      return delay + (isOpen ? 200 : 0);
    }
  });

  const listItemAnim = anime({
    targets: navListItems,
    duration: isOpen ? 500 : 300,
    opacity: isOpen ? [0, 1] : [1, 0],
    translateY: isOpen ? [0, '-1rem'] : ['-1rem', 0],
    easing: easings.easeOut,
    delay: (el, i) => (isOpen ? i * 25 : 0)
  });

  const wrapperFadeAnim = anime({
    targets: navList,
    duration: isOpen ? 200 : 200,
    opacity: isOpen ? [0, 1] : [1, 0],
    easing: easings.easeOut
  });

  // const wrapperColorAnim = anime({
  //   targets: navList,
  //   duration: isOpen ? 500 : 300,
  //   backgroundColor: isOpen
  //     ? [colors.blue, colors.black]
  //     : [colors.black, colors.blue],
  //   easing: easings.easeOutColor,
  //   delay: 100
  // });

  const anims = [
    iconLineAnim.finished,
    iconArrowAnim.finished,
    wrapperFadeAnim.finished,
    // wrapperColorAnim.finished,
    listItemAnim.finished
  ];

  Promise.all(anims).then(_ => {
    if (!isOpen) navList.style.visibility = 'hidden';
    isAnimating = false;
  });
}

function resetNav() {
  const navToggle = $('.js-nav-toggle');
  const navList = $('.js-nav-list');
  const navListItems = $$('.js-nav-list > *');
  const navIconLineEls = $$('.js-nav-icon > *:nth-child(-n + 3)');
  const navIconArrowEls = $$('.js-nav-icon > *:nth-last-child(-n + 3)');
  navToggle.setAttribute('aria-expanded', 'false');
  navList.removeAttribute('style');
  navListItems.forEach(el => el.removeAttribute('style'));
  navIconLineEls.forEach(el => el.removeAttribute('style'));
  navIconArrowEls.forEach(el => el.removeAttribute('style'));
  isOpen = false;
  isAnimating = false;
}

function nav() {
  // Delegate click
  page.addEventListener('click', function (e) {
    // Alternative: e.target.matches('.js-nav-toggle, .js-nav-toggle *'),
    // but needs polyfill for older mobile browsers
    if (e.target) {
      if (e.target.classList.contains('js-nav-toggle')) {
        animateNav();
      } else if (
        e.target.classList.contains('js-nav-link') &&
        !viewport.m.matches
      ) {
        animateNav();
      }
    }
  });

  // Reset nav on wide viewport
  window.addEventListener(
    'resize',
    throttle(function () {
      if (viewport.m.matches) {
        resetNav();
      }
    }, 100)
  );

  // Reset nav if scrolled out of view
  const navList = $('.js-nav-list');

  window.addEventListener(
    'scroll',
    throttle(function () {
      if (isOpen && window.pageYOffset > navList.offsetHeight) {
        resetNav();
      }
    }, 100)
  );
}

export default nav;
