function inView(el, offset = 1) {
  const rect = el.getBoundingClientRect();
  const top = rect.top;
  const height = rect.height;
  let value = {};

  // Visible in viewport, offset = % if viewport height
  value.visible = top < window.innerHeight * offset && top + rect.height > 0;

  // Relative position in viewport
  let relativePosition = 1 - (top + height) / (window.innerHeight + height);
  value.position = Math.round(relativePosition * 1000) / 1000;

  return value;
}

export default inView;
