// Match scss vars
const viewport = {
  s: window.matchMedia(`(min-width: ${576 / 16}em)`),
  m: window.matchMedia(`(min-width: ${768 / 16}em)`),
  l: window.matchMedia(`(min-width: ${992 / 16}em)`),
  xl: window.matchMedia(`(min-width: ${1200 / 16}em)`),
  xxl: window.matchMedia(`(min-width: ${1500 / 16}em)`)
};

const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

const saveData =
  'connection' in navigator && navigator.connection.saveData === true;

const easings = {
  easeOut: 'cubicBezier(0.25, 0.65, 0, 1)',
  easeInOut: 'cubicBezier(0.65, 0, 0, 1)',
  easeOutColor: 'easeOutSine'
};

const colors = {
  blue: 'rgba(0, 68, 255, 1)',
  blueTransparent: 'rgba(0, 68, 255, 0)',
  black: 'rgba(47, 47, 45, 1)'
};

export { viewport, reducedMotion, saveData, easings, colors };
