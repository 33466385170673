import { $, $$ } from '../helpers/query-selector';

function init() {
  const teaser = $('.js-journal-teaser');

  if (!teaser) return;
  const items = $$('.js-journal-teaser-item');
  const link = $('.js-journal-teaser-link');
  const teaserAnimateClass = 'l-journal-teaser--animate';
  const linkAnimateClass = 'l-journal-teaser__link--animate';

  for (const item of items) {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        for (const entry of entries) {
          if (
            entry.isIntersecting &&
            !teaser.classList.contains(teaserAnimateClass)
          ) {
            teaser.classList.add(teaserAnimateClass);
          } else if (teaser.classList.contains(teaserAnimateClass)) {
            observer.unobserve(item);
          }
        }
      },
      { rootMargin: `0% 0% -80px 0%` }
    );
    observer.observe(item);
  }

  const linkObserver = new IntersectionObserver(
    (entries, linkObserver) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.add(linkAnimateClass);
        } else if (entry.target.classList.contains(linkAnimateClass)) {
          linkObserver.unobserve(link);
        }
      }
    },
    { rootMargin: `0% 0% -50px 0%` }
  );
  linkObserver.observe(link);
}

export default init;
