import { $, $$ } from '../helpers/query-selector';

function init() {
  const journalList = $('.js-journal-list');
  const journalListAnimateClass = 'l-journal-list__item--animate';

  if (!journalList) return;
  const items = $$('.js-journal-list-item');

  for (const item of items) {
    const observer = new IntersectionObserver((entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.add(journalListAnimateClass);
        } else if (entry.target.classList.contains(journalListAnimateClass)) {
          observer.unobserve(item);
        }
      }
    });
    observer.observe(item);
  }
}

export default init;
