import '../scss/app.scss'; // Vite requires css import in app.js

import initLazyLoad from './modules/lazyload';
import initSetVh from './modules/set-vh';
import initNav from './modules/nav';
import initPageTransition from './modules/page-transition';

function init() {
  initLazyLoad();
  initSetVh();
  initNav();
  initPageTransition();
}

init();
